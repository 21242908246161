@import url("https://fonts.googleapis.com/css?family=Roboto:300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins");



.login-box {
  padding: 4vw;
  margin-left: 30vw;
  margin-right: 25vw;
  margin-top: 10vw;

  border: 1px solid #000;
  background-color: grey;
  width: fit-content;
  border-radius: 10px;
  box-shadow: 0.3vw 0.3vw 0.3vw rgba(0.25, 0.25, 0.25, 0.25);
}
.para-text {
  font-family: Poppins;
  font-size: large;
  color: #fff;
}
.input-fields {
  width: 20vw;
  border: none;
  padding: 0.5vw;
  font-family: Poppins;
  border-radius: 5px;
}
.button-div {
  width: fit-content;
  padding: 4vw;
}
.reg-butt {
  font-family: Poppins;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-right: 2vw;
  background-color: black;
  font-size: large;
  border: none;
  border-radius: 5px;
  color: #fff;
}
.login-butt {
  font-family: Poppins;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-right: 2vw;
  background-color: black;
  font-size: large;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.cus-contact {
  background: #fff;
  padding-left: 20px;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 80%;
}

.table-in-card {
  background: white;
  padding: 20px;
  margin-top: 30px;
}
.table-invoice .table-in-card .table thead tr th {
  color: #999999;
  border-bottom: 0;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btnnnnn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.content{
  
  
  width: 100%;
}
.content .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin-left: -30px;
  margin-right: -30px;

}

.table-in-card {
  background: white;
  padding: 20px;
  margin-top: 30px;
}
.table-invoice .table-in-card .table thead tr th {
  color: #999999;
  border-bottom: 0;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

/* media screen and (max-width: 1170px)
---------------------------------------------------------------------- */
@media screen and (max-width:1170px) {
  .container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
  }
}
/* media screen and (max-width: 728px)
---------------------------------------------------------------------- */
@media screen and (max-width:728px) {}
/* media screen and (max-width: 640px)
---------------------------------------------------------------------- */
@media screen and (max-width:640px) {}
/* media screen and (max-width: 550px)
---------------------------------------------------------------------- */
@media screen and (max-width:550px) {}
/* media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media screen and (max-width:500px) {}
/* media screen and (max-width: 414px)
---------------------------------------------------------------------- */
@media screen and (max-width:414px) {}