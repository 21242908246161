@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins);
.login-box {
  padding: 4vw;
  margin-left: 30vw;
  margin-right: 25vw;
  margin-top: 10vw;

  border: 1px solid #000;
  background-color: grey;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  box-shadow: 0.3vw 0.3vw 0.3vw rgba(0.25, 0.25, 0.25, 0.25);
}
.para-text {
  font-family: Poppins;
  font-size: large;
  color: #fff;
}
.input-fields {
  width: 20vw;
  border: none;
  padding: 0.5vw;
  font-family: Poppins;
  border-radius: 5px;
}
.button-div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4vw;
}
.reg-butt {
  font-family: Poppins;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-right: 2vw;
  background-color: black;
  font-size: large;
  border: none;
  border-radius: 5px;
  color: #fff;
}
.login-butt {
  font-family: Poppins;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-right: 2vw;
  background-color: black;
  font-size: large;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.cus-contact {
  background: #fff;
  padding-left: 20px;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 80%;
}

.table-in-card {
  background: white;
  padding: 20px;
  margin-top: 30px;
}
.table-invoice .table-in-card .table thead tr th {
  color: #999999;
  border-bottom: 0;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btnnnnn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.content{
  
  
  width: 100%;
}
.content .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin-left: -30px;
  margin-right: -30px;

}

.table-in-card {
  background: white;
  padding: 20px;
  margin-top: 30px;
}
.table-invoice .table-in-card .table thead tr th {
  color: #999999;
  border-bottom: 0;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

/* media screen and (max-width: 1170px)
---------------------------------------------------------------------- */
@media screen and (max-width:1170px) {
  .container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
  }
}
/* media screen and (max-width: 728px)
---------------------------------------------------------------------- */
@media screen and (max-width:728px) {}
/* media screen and (max-width: 640px)
---------------------------------------------------------------------- */
@media screen and (max-width:640px) {}
/* media screen and (max-width: 550px)
---------------------------------------------------------------------- */
@media screen and (max-width:550px) {}
/* media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media screen and (max-width:500px) {}
/* media screen and (max-width: 414px)
---------------------------------------------------------------------- */
@media screen and (max-width:414px) {}
.left2 {
  width: 200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #354052;
  position: fixed;
  transition: all 0.5s;
  overflow: hidden;
}

.left2:hover ul li.menu-heading {
  opacity: 1;
}

.left2 ul {
  padding-left: 0;
}

.left2 ul li {
  list-style-type: none;
}

.left2 ul li.active a {
  background: #00b300;
  color: white;
}

.left2 ul li.active a:hover {
  background: #009a00;
}

.left2 ul li a {
  display: flex;
  align-items: center;
  background: #354052;
  color: white;
  padding: 15px 10px;
  font-size: 13px;
  width: 200px;
  border-bottom: 1px solid #2b3443;
}

.left2 ul li a:hover {
  background: #2b3443;
  text-decoration: none;
}

.left2 ul li a:hover i.fa {
  color: #00b300;
}

.left2 ul li a:focus {
  text-decoration: none;
}

.left2 ul li a i.fa {
  width: 50px;
  text-align: center;
  padding-right: 15px;
  transition: all 1s;
}

.left2 ul .menu-heading {
  opacity: 0;
  padding: 10px 5px 10px 10px;
  color: #b3b300;
  font-size: smaller;
  border-bottom: 1px solid #2b3443;
  transition: all 0.5s;
}

.dropbtn {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #354052;
  color: white;
  padding: 15px 10px;
  font-size: 13px;
  width: 200px;
  border-bottom: 1px solid #2b3443;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* .dropdown:hover .dropdown-content {
    display: block;
  } */

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

/* Custom scrollbar */
.content ::-webkit-scrollbar {
  width: 5px;
}

.content ::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content ::-webkit-scrollbar-thumb {
  background: #5d7090;
  border-radius: 10px;
}

.content ::-webkit-scrollbar-thumb:hover {
  background: #495871;
}
.banner {
  position: fixed;
  bottom: -500px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200px;
background-color: #F14D20;
  transition: bottom 0.5s ease-in-out;
  display: flex;
}

.banner.show {
  bottom: 0;
}
.bannercontent{
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.banner-phone{
    position: relative;
    bottom: 150px;
    left: 150px;
    height: 69%;
}
.close-button{
    background: none;
    border: none;
    position: relative;
        bottom: 10px;
        right: -50px;
}
.applinkimg{
    width: 70%;
}
.appqr{
    width: 65%;
}
@media only screen and (max-width: 767px) {

  
  .banner.show,.banner {
    display: none;
  }
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(150px, auto);
}

.grid-item {
    margin: 10px;
    max-width:280px;
    max-height:280px;
    
}
.postsimage{
    object-fit: cover;
}
@media only screen and (max-width: 450px) {
    .grid-container {
            display: grid !important;
            grid-template-columns: repeat(2, 2fr) !important;
            grid-auto-rows: minmax(150px, auto) !important;
        }
}
