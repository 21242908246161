.banner {
  position: fixed;
  bottom: -500px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200px;
background-color: #F14D20;
  transition: bottom 0.5s ease-in-out;
  display: flex;
}

.banner.show {
  bottom: 0;
}
.bannercontent{
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.banner-phone{
    position: relative;
    bottom: 150px;
    left: 150px;
    height: 69%;
}
.close-button{
    background: none;
    border: none;
    position: relative;
        bottom: 10px;
        right: -50px;
}
.applinkimg{
    width: 70%;
}
.appqr{
    width: 65%;
}
@media only screen and (max-width: 767px) {

  
  .banner.show,.banner {
    display: none;
  }
}