.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(150px, auto);
}

.grid-item {
    margin: 10px;
    max-width:280px;
    max-height:280px;
    
}
.postsimage{
    object-fit: cover;
}
@media only screen and (max-width: 450px) {
    .grid-container {
            display: grid !important;
            grid-template-columns: repeat(2, 2fr) !important;
            grid-auto-rows: minmax(150px, auto) !important;
        }
}