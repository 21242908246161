.left2 {
  width: 200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #354052;
  position: fixed;
  transition: all 0.5s;
  overflow: hidden;
}

.left2:hover ul li.menu-heading {
  opacity: 1;
}

.left2 ul {
  padding-left: 0;
}

.left2 ul li {
  list-style-type: none;
}

.left2 ul li.active a {
  background: #00b300;
  color: white;
}

.left2 ul li.active a:hover {
  background: #009a00;
}

.left2 ul li a {
  display: flex;
  align-items: center;
  background: #354052;
  color: white;
  padding: 15px 10px;
  font-size: 13px;
  width: 200px;
  border-bottom: 1px solid #2b3443;
}

.left2 ul li a:hover {
  background: #2b3443;
  text-decoration: none;
}

.left2 ul li a:hover i.fa {
  color: #00b300;
}

.left2 ul li a:focus {
  text-decoration: none;
}

.left2 ul li a i.fa {
  width: 50px;
  text-align: center;
  padding-right: 15px;
  transition: all 1s;
}

.left2 ul .menu-heading {
  opacity: 0;
  padding: 10px 5px 10px 10px;
  color: #b3b300;
  font-size: smaller;
  border-bottom: 1px solid #2b3443;
  transition: all 0.5s;
}

.dropbtn {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #354052;
  color: white;
  padding: 15px 10px;
  font-size: 13px;
  width: 200px;
  border-bottom: 1px solid #2b3443;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* .dropdown:hover .dropdown-content {
    display: block;
  } */

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

/* Custom scrollbar */
.content ::-webkit-scrollbar {
  width: 5px;
}

.content ::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content ::-webkit-scrollbar-thumb {
  background: #5d7090;
  border-radius: 10px;
}

.content ::-webkit-scrollbar-thumb:hover {
  background: #495871;
}